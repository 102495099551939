import Vue from 'vue'
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'
import axios from '@/utils/axios'
import { showError, showSuccess } from '@/helpers/notifications'
import { AxiosResponse } from 'axios'
import { IOrder } from '@/interfaces/IOrder'
import { userStore } from './userStore'
import { OrderStatus } from '@/enums/orderStatus'

@Module({
  name: 'ordersStore',
  namespaced: true,
  dynamic: true,
  store,
})
export default class OrdersStore extends VuexModule {
  private _orders: IOrder[] = []
  private _ordersCount: number = 0
  private _lastOrderTime: string | null = null
  private _page: number = 1
  private _perPage: number = 10
  private _showCompleted: boolean = false

  get orders() {
    if (this.showCompleted) {
      return this._orders
    }
    return this._orders.filter(
      order =>
        order.status !== OrderStatus.completed &&
        order.status !== OrderStatus.canceled &&
        order.status !== OrderStatus.rejected &&
        order.status !== OrderStatus.waiting,
    )
  }

  get ordersCount() {
    return this._ordersCount
  }

  get page() {
    return this._page
  }

  get perPage() {
    return this._perPage
  }

  get showCompleted() {
    return this._showCompleted
  }

  @Mutation
  public setPage(page: number) {
    this._page = page
  }

  @Mutation
  public setShowCompleted(value: boolean) {
    this._showCompleted = value
  }

  @Mutation
  private setOrders(orders: IOrder[]) {
    this._orders = orders

    if (this._page === 1) {
      if (orders.length > 0) {
        this._lastOrderTime = orders[0].createdAt
      }
    }
  }

  @Mutation
  private addNewOrders(orders: IOrder[]) {
    if (orders.length === 0) {
      return
    }
    this._lastOrderTime = orders[0].createdAt

    if (this._page === 1) {
      this._orders = [...orders, ...this._orders]
    }
  }

  @Mutation
  private setOrdersCount(count: number) {
    this._ordersCount = count
  }

  @Mutation
  private updateOrder(order: IOrder) {
    const index = this._orders.findIndex(o => o.id === order.id)
    Vue.set(this._orders, index, order)
  }

  @Action
  async fetchOrders() {
    if (!userStore.selectedShop) {
      return
    }

    try {
      const showCompleted = this._showCompleted ? `&showCompleted=true` : ''

      const response: AxiosResponse<{ orders: IOrder[]; total: number }> = await axios.get(
        `orders/${userStore.selectedShop}?page=${this._page}&perPage=${this._perPage}${showCompleted}`,
      )
      this.setOrders(response.data.orders)
      this.setOrdersCount(response.data.total)
    } catch (error) {
      console.error(error)
      showError('При получении заказов произошла ошибка')
    }
  }

  @Action
  async checkLastOrders() {
    if (!userStore.selectedShop) {
      return
    }

    try {
      const from = this._lastOrderTime ? `?from=${this._lastOrderTime}` : ''

      const response: AxiosResponse<IOrder[]> = await axios.get(
        `orders/last/${userStore.selectedShop}${from}`,
      )

      this.addNewOrders(response.data)
    } catch (error) {
      console.error(error)
      showError('При проверке последних заказов произошла ошибка')
    }
  }

  @Action
  async moveOrderToNextStatus(orderId: string) {
    try {
      const response = await axios.patch(`orders/${orderId}/next-status`)
      this.updateOrder(response.data)
    } catch (error) {
      console.error(error)
      showError('При смене статуса произошла ошибка')
    }
  }

  @Action
  async cancelOrder({ orderId, comment }: { orderId: string; comment: string }) {
    try {
      const response = await axios.patch(`orders/${orderId}/cancel`, { comment })
      this.updateOrder(response.data)
    } catch (error) {
      console.error(error)
      showError('При отмене заказа произошла ошибка')
    }
  }

  @Action
  async updateOrderInfo(id: string) {
    try {
      const response = await axios.get(`orders/order/${id}`)
      this.updateOrder(response.data)
    } catch (error) {
      console.error(error)
      showError('При проверке заказа произошла ошибка')
    }
  }
}

export const ordersStore = getModule(OrdersStore)
