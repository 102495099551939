
import { ordersStore } from '@/store/ordersStore'
import { Component, Vue, Ref } from 'vue-property-decorator'

@Component
export default class OrdersNotificationModal extends Vue {
  @Ref() readonly notificationAudio!: HTMLAudioElement

  notifyInterval: ReturnType<typeof setInterval> | null = null
  isOpen = false
  isWindowFocused = true

  mounted() {
    if (!('Notification' in window)) {
      console.error('This browser does not support desktop notification')
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission()
    }

    window.addEventListener('blur', this.onBlur)
    window.addEventListener('focus', this.onFocus)
  }

  beforeDestroy() {
    window.removeEventListener('focus', this.onBlur)
    window.removeEventListener('focus', this.onFocus)
  }

  onBlur() {
    this.isWindowFocused = false
  }

  onFocus() {
    this.isWindowFocused = true
  }

  show() {
    if (this.isOpen === true || this.isWindowFocused) {
      return
    }

    this.isOpen = true
    this.$bvModal.show('orders-notification-modal')
    // this.notifyInterval = setInterval(this.notify, 30000)
    this.notify()
  }

  notify() {
    setTimeout(() => this.notificationAudio?.play(), 100)

    if (!('Notification' in window)) {
      console.error('This browser does not support desktop notification')
    } else if (Notification.permission === 'granted') {
      this.showNotification()
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission(permission => {
        if (permission === 'granted') {
          this.showNotification()
        }
      })
    }
  }

  showNotification() {
    new Notification('Появились новые заказы', {
      icon: 'https://admin.dogshaus.ru/favicon.png',
      body: 'Перейти к вкладке',
    }).onclick = () => window.focus()
  }

  handleOk() {
    this.$bvModal.hide('orders-notification-modal')
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    if (ordersStore.page !== 1) {
      ordersStore.setPage(1)
    }
  }

  reset() {
    this.isOpen = false
    if (this.notifyInterval) {
      clearInterval(this.notifyInterval)
    }
  }
}
