
import { OrderStatus } from '@/enums/orderStatus'
import { IOrder } from '@/interfaces/IOrder'
import { Component, Vue } from 'vue-property-decorator'
import declination from '@/helpers/declination'

@Component
export default class OrderStatusModal extends Vue {
  statuses: { status: OrderStatus; createdAt: string; user?: { email: string } }[] | null = null

  show(order: IOrder) {
    this.statuses = order.statusEvents
    this.$bvModal.show('order-status-modal')
  }

  getStatusText(status: OrderStatus) {
    switch (status) {
      case OrderStatus.waiting:
        return 'Ожидает оплаты'
      case OrderStatus.rejected:
        return 'Оплата не прошла'
      case OrderStatus.new:
        return 'Создан'
      case OrderStatus.accepted:
        return 'Принят в работу'
      case OrderStatus.delivery:
        return 'Передан в доставку'
      case OrderStatus.completed:
        return 'Доставлен'
      case OrderStatus.canceled:
        return 'Отменён'
    }
  }

  getBadgeVariant(status: OrderStatus) {
    switch (status) {
      case OrderStatus.waiting:
        return 'bg-secondary'
      case OrderStatus.new:
        return 'bg-info'
      case OrderStatus.accepted:
      case OrderStatus.delivery:
        return 'bg-secondary'
      case OrderStatus.completed:
        return 'bg-success'
      case OrderStatus.rejected:
      case OrderStatus.canceled:
        return 'bg-danger'
    }
  }

  formatTime(time: string) {
    return new Date(time).toLocaleString('ru')
  }

  getTimeDiff(index: number) {
    const startTime = new Date(this.statuses![index].createdAt)
    const endTime = new Date(this.statuses![index + 1].createdAt)
    const diff = endTime.getTime() - startTime.getTime()

    const seconds = Math.floor((diff % (1000 * 60)) / 1000)
    const minutes = Math.floor((diff / 1000 / 60) % 60)
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24)
    const days = Math.floor(diff / (1000 * 60 * 60 * 24))

    const daysString = days ? `${days} ${declination(days, ['день', 'дня', 'дней'])}` : ''
    const hoursString = hours ? `${hours} ${declination(hours, ['час', 'часа', 'часов'])}` : ''
    const minutesString = minutes
      ? `${minutes} ${declination(minutes, ['минута', 'минуты', 'минут'])}`
      : ''
    const secondsString = seconds
      ? `${seconds} ${declination(seconds, ['секунда', 'секунды', 'секунд'])}`
      : ''

    return `${daysString} ${hoursString} ${minutesString} ${secondsString}`
  }

  reset() {
    this.statuses = null
  }
}
