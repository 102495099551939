
import { Component, Vue, Watch, Ref } from 'vue-property-decorator'
import { ordersStore } from '@/store/ordersStore'
import { userStore } from '@/store/userStore'
import OrderCard from '@/components/orders/OrderCard.vue'
import OrdersNotificationModal from '@/components/modals/OrderNotificationModal.vue'
import OrderStatusModal from '@/components/modals/OrderStatusModal.vue'
import OrderCancelingModal from '@/components/modals/OrderCancelingModal.vue'
import { IOrder } from '@/interfaces/IOrder'
import { loadFromLS, saveToLS } from '@/helpers/localStorage'
import { OrderStatus } from '@/enums/orderStatus'

@Component({
  components: {
    OrderCard,
    OrdersNotificationModal,
    OrderStatusModal,
    OrderCancelingModal,
  },
})
export default class Orders extends Vue {
  @Ref() readonly notificationModal!: { show: () => void }
  @Ref() readonly orderCancelingModal!: { show: (order: IOrder) => void }

  pending = false

  checkInterval: ReturnType<typeof setInterval> | null = null

  get orders() {
    return ordersStore.orders
  }

  get ordersCount() {
    return ordersStore.ordersCount
  }

  get showCompleted() {
    return ordersStore.showCompleted
  }
  set showCompleted(value: boolean) {
    ordersStore.setShowCompleted(value)
    saveToLS('showCompletedOrders', value)
  }

  get page() {
    return ordersStore.page
  }
  set page(page: number) {
    ordersStore.setPage(page)
  }

  get perPage() {
    return ordersStore.perPage
  }

  get selectedShop() {
    return userStore.selectedShop
  }

  @Watch('page')
  @Watch('showCompleted')
  async onPageChanged() {
    await this.fetchOrders()
  }

  @Watch('selectedShop')
  async onShopChanged() {
    await this.fetchOrders()
  }

  async mounted() {
    this.showCompleted = loadFromLS<boolean>('showCompletedOrders') || false
    await this.fetchOrders()
    this.checkInterval = setInterval(this.checkNewOrders, 5000)
  }

  beforeDestroy() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval)
    }
  }

  async checkNewOrders() {
    await ordersStore.checkLastOrders()

    for (const order of this.orders) {
      if (order.status === OrderStatus.waiting) {
        await ordersStore.updateOrderInfo(order.id)
      }
    }

    if (this.orders.filter(order => order.status === OrderStatus.new).length) {
      this.notificationModal.show()
    }
  }

  async fetchOrders() {
    this.pending = true
    await ordersStore.fetchOrders()
    this.pending = false
  }
}
