
import { showError } from '@/helpers/notifications'
import { IOrder } from '@/interfaces/IOrder'
import { ordersStore } from '@/store/ordersStore'
import { BvEvent } from 'bootstrap-vue'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class OrderCancelingModal extends Vue {
  comment = ''
  order: IOrder | null = null

  show(order: IOrder) {
    this.order = order
    this.$bvModal.show('order-canceling-modal')
  }

  handleOk(e: BvEvent) {
    if (this.comment) {
      ordersStore.cancelOrder({
        orderId: this.order!.id,
        comment: this.comment,
      })
      this.$bvModal.hide('order-canceling-modal')
    } else {
      showError('Введите комментарий')
      e.preventDefault()
    }
  }

  reset() {
    this.comment = ''
    this.order = null
  }
}
