
import { Component, Vue, Prop } from 'vue-property-decorator'
import { PaymentType } from '@/enums/paymentType'
import { DeliveryType } from '@/enums/deliveryType'
import { OrderStatus } from '@/enums/orderStatus'
import { IProduct } from '@/interfaces/IProduct'
import { ordersStore } from '@/store/ordersStore'

@Component
export default class OrderCard extends Vue {
  @Prop({ type: String, required: true })
  id!: string
  @Prop({ type: Number, required: true })
  number!: number
  @Prop({ type: String, required: true })
  paymentType!: PaymentType
  @Prop({ type: String })
  deliveryType?: DeliveryType
  @Prop({ type: Array, required: true })
  statusEvents!: { status: OrderStatus; createdAt: string }[]
  @Prop({ type: String, required: true })
  name!: string
  @Prop({ type: String })
  address?: string
  @Prop({ type: String, required: true })
  phone!: string
  @Prop({ type: String })
  comment?: string
  @Prop({ type: Number, required: true })
  price!: number
  @Prop({ type: String, required: true })
  status!: OrderStatus
  @Prop({ type: String })
  sberOrderId!: string | null
  @Prop({ type: Number, required: true })
  deliveryPrice!: number
  @Prop({ type: Number, required: true })
  totalPrice!: number
  @Prop({ type: String, required: true })
  createdAt!: string
  @Prop({ type: Array, required: true })
  products!: {
    count: number
    title: string
    price: number
    product: IProduct
  }[]

  pending = false

  get sberLink() {
    return `${process.env.VUE_APP_SBER_LK}/mportal3/admin/orders/${this.sberOrderId}`
  }

  get deliveryTypeText() {
    switch (this.deliveryType) {
      case DeliveryType.pickup:
        return 'Самовывоз'
      default:
        return 'Доставка'
    }
  }

  get statusButtonText() {
    switch (this.status) {
      case OrderStatus.waiting:
        return 'Ожидает оплаты'
      case OrderStatus.rejected:
        return 'Оплата не прошла'
      case OrderStatus.new:
        return 'Принять заказ'
      case OrderStatus.accepted:
        return 'Передать в доставку'
      case OrderStatus.delivery:
        return 'Завершить заказ'
      case OrderStatus.completed:
        return 'Завершён'
      case OrderStatus.canceled:
        return 'Отменён'
    }
  }

  get statusButtonVariant() {
    switch (this.status) {
      case OrderStatus.new:
        return 'primary'
      case OrderStatus.accepted:
      case OrderStatus.delivery:
        return 'outline-primary'
      case OrderStatus.completed:
        return 'success'
      case OrderStatus.rejected:
      case OrderStatus.canceled:
        return 'danger'
    }
  }

  get createdDate() {
    return new Date(this.createdAt).toLocaleString('ru', {
      day: 'numeric',
      month: '2-digit',
      year: '2-digit',
      weekday: 'long',
      minute: 'numeric',
      hour: '2-digit',
    })
  }

  get PaymentType() {
    return PaymentType
  }

  get OrderStatus() {
    return OrderStatus
  }

  get bgClass() {
    switch (this.status) {
      case OrderStatus.new:
        return 'bg-success'
      case OrderStatus.completed:
      // return 'bg-success'
      case OrderStatus.canceled:
      // return 'bg-secondary'
      default:
        return 'bg-white'
    }
  }

  onStatusButtonClick() {
    switch (this.status) {
      case OrderStatus.new:
        return this.moveToNextStatus('Принять заказ?')
      case OrderStatus.accepted:
        return this.moveToNextStatus('Передать заказ в доставку?')
      case OrderStatus.delivery:
        return this.moveToNextStatus('Завершить заказ?')
      case OrderStatus.completed:
        return
      case OrderStatus.canceled:
        return
    }
  }

  async moveToNextStatus(title: string) {
    const result = await this.$bvModal.msgBoxConfirm(title, {
      title: 'Подтверждение',
      size: 'sm',
      headerClass: 'p-1 px-3',
      footerClass: 'p-1 px-3',
      okTitle: 'Да',
      cancelTitle: 'Отмена',
      centered: true,
    })

    if (result) {
      this.pending = true
      await ordersStore.moveOrderToNextStatus(this.id)
      this.pending = false
    }
  }
}
